import * as React from "react"
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "./styles.css";
import styled from 'styled-components';
import Hero from '../images/claims.jpg';
import { Helmet } from "react-helmet";

import Intact from '../images/INSURANCEIntact logo_MASTER_RGB.png';
import Aviva from '../images/Aviva.png';
import Unica from '../images/Unica_rgb.png';
import Berkley from '../images/BUC_2017_Logo_Final.png';
import Bim from '../images/BIM_logo_.jpg';
import MK_R_GG from '../images/MK_R_GG.png';
import GA from '../images/GA.jpg';
import Premier from '../images/Premier_logo_with_Tagline.jpg';
import Chubb from '../images/CHUBB_Logo_DarkBlue_RGB.png';
import Coalition from '../images/Coalition-black-Logo.png';
import Evolution from '../images/logo_evolution_anglais_small.png';
import Trinity from '../images/Trinity-Logo -Vertical.png';
import Cansure from '../images/Cansure_Logo_2020.png';
import Abex from '../images/ABEX-logo-2013.jpg';

const Header = styled.div`
`;

const HeroImg = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

const TitleBox = styled.div`
    background: #e0e0e0;
    position: relative;
    top: -100px;
    max-width: 500px;
    height: 200px;
    opacity: 90%;

    @media screen and (max-width: 600px) {
      max-width: 75%;
      height: 150px;
    }
`;

const Heading = styled.h1`
  padding:60px 40px 40px 0;
  font-size:50px;
  text-align: center;
  font-family: 'Vollkorn', serif;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size:40px;
  }
`;

const ClaimsSection = styled.div`
font-family: Poppins, sans-serif;
    max-width: 1000px;
    margin: -100px auto 0px;
    padding: 50px 20px;
}
`;

const Container = styled.div`
 height: auto;
 width: auto;
 display: inline-flex;
 justify-content: space-around;
 padding: 0 20px 20px 20px;
 align-items: flex-start;
 margin-top: 60px;

 @media screen and (max-width: 600px) {
    width: 100%;
    display: block;
 }
`;

const Items = styled.div`
    display: block;
    padding: 20px;
    width: 300px;
    height: 100%;

    @media screen and (max-width: 600px) {
        display: block;
        margin: 0 auto;
    }

    img {
        max-width: 150px;
        width: 100%;
        height: 100%;
    }
    
`;


const About = () => {
  return (
    <main>
      <Helmet>
      <title>About</title>
      <meta name="description" content="Dunning Currie Insurance Inc. is an independently owned Canadian Property &amp; Casualty premier insurance brokerage. " />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta charSet="utf-8" />
      </Helmet>
      <Navigation />
      <Header>
         <HeroImg src={Hero} alt="hero image" />
          <TitleBox><Heading>Claims</Heading></TitleBox>
      </Header>
        <ClaimsSection>
            <h3>In the event of a claim:</h3>
            <ul>
                <li>Remain calm and ensure all persons and property involved are safe.  Take any necessary action to prevent further loss or damage without placing yourself or others at risk of personal injury.</li>
                <li>If required, please call emergency services.</li>
                <li>Report the claim as soon as possible.  Please note claims involving bodily injury must be reported immediately. Claims involving collision or property damage should be made within 48 hours.</li>
                <li>The below list of contact information for various insurance companies may be helpful.</li>
            </ul>
            <Container>
                <Items>
                    <img src={Intact} alt="Intact" />
                    <p>1-866-464-2424</p>
                    <p>firstnoticeofloss@intact.net</p>
                </Items>
                <Items>
                    <img src={Aviva} alt="Aviva" />
                    <p>1-866-692-8482 (1-866-MY-AVIVA)</p>
                    <p>Auto Claims email (PL &amp; CL):  carecentre_ont@aviva.com</p>
                    <p>Property Claims email:  propertyclaims.canada@aviva.com</p>
                    <p>Ovation (Property &amp; Auto): ovationclaims@aviva.com</p>
                    <p>Lifestyle (Boats, Cottages, Trailers etc): lifestyle.canada@aviva.com</p>
                </Items>
                <Items>
                <img src={Unica} alt="Unica" />
                    <p>Regular-hours: 1-800-676-0967</p>
                    <p>After-hours emergency line: 1-866-864-1113</p>
                    <p>claims@unicainsurance.com</p>
                </Items>
            </Container>
            <Container>
            <Items>
                <img src={Chubb} alt="Chubb" />
                    <p>1-800-532-4822</p>
                    <p>canadaclaims@chubb.com</p>
                </Items>
                <Items>
                <img src={Abex} alt="Abex" />
                    <p>1-877-313-2585</p>
                    <p>claims@abexinsurance.com </p>
                    <p>After Hours: newabexclaims@crawco.ca</p>
                </Items>
                <Items>
                <img src={Premier} alt="Premier" />
                    <p>1-833-376-8436</p>
                    <p>claims@premiergroup.ca</p>
                </Items>
            </Container>
        </ClaimsSection>
      <Footer />
    </main>
  )
}

export default About

export const Head = () => <title>About</title>
